import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { getContent, getQueryStringResults } from '@plone/volto/actions';
import { getBaseUrl, usePagination } from '@plone/volto/helpers';

import config from '@plone/volto/registry';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withQuerystringResults(WrappedComponent) {
  const MemoizedWrappedComponent = React.memo(WrappedComponent);

  function WithQuerystringResults(props) {
    const {
      data = {},
      id = data.block,
      properties: content,
      path,
      variation,
    } = props;
    const { settings } = config;
    const querystring = data.querystring || data; // For backwards compatibility with data saved before Blocks schema.
    const subrequestID = content?.UID ? `${content?.UID}-${id}` : id;
    const { b_size = settings.defaultPageSize } = querystring;

    const initialPath = useMemo(() => getBaseUrl(path), [path]);
    const copyFields = ['limit', 'query', 'sort_on', 'sort_order', 'depth'];
    const { currentPage, setCurrentPage } = usePagination(id, 1);

    const adaptedQuery = useMemo(() => {
      return Object.assign(
        variation?.fullobjects ? { fullobjects: 1 } : { metadata_fields: '_all' },
        { b_size },
        ...copyFields.map((name) =>
          Object.keys(querystring).includes(name)
            ? { [name]: querystring[name] }
            : {}
        )
      );
    }, [variation, querystring, b_size]);

    const previousQueryRef = useRef();
    const querystringResults = useSelector(
      (state) => state.querystringsearch.subrequests,
    );
    const dispatch = useDispatch();

    const folderItems = content?.is_folderish ? content.items : [];
    const hasQuery = querystring?.query?.length > 0;
    const hasLoaded = hasQuery
      ? querystringResults?.[subrequestID]?.loaded
      : true;

    const listingItems = hasQuery
      ? querystringResults?.[subrequestID]?.items || []
      : folderItems;

    const showAsFolderListing = !hasQuery && content?.items_total > b_size;
    const showAsQueryListing =
      hasQuery && querystringResults?.[subrequestID]?.total > b_size;

    const totalPages = showAsFolderListing
      ? Math.ceil(content.items_total / b_size)
      : showAsQueryListing
        ? Math.ceil(querystringResults[subrequestID]?.total / b_size)
        : 0;

    const prevBatch = showAsFolderListing
      ? content.batching?.prev
      : showAsQueryListing
        ? querystringResults[subrequestID]?.batching?.prev
        : null;
    const nextBatch = showAsFolderListing
      ? content.batching?.next
      : showAsQueryListing
        ? querystringResults[subrequestID]?.batching?.next
        : null;

    const isImageGallery =
      (!data.variation && data.template === 'imageGallery') ||
      data.variation === 'imageGallery';

    const [isSSR, setIsSSR] = useState(true);

    useEffect(() => {
      if (typeof window !== 'undefined') {
        setIsSSR(false);
      }
    }, []);

    const fetchResults = useCallback(() => {
      if (
        isSSR ||
        JSON.stringify(previousQueryRef.current) === JSON.stringify({ adaptedQuery, currentPage })
      ) {
        return; // Skip fetch if SSR or query hasn't changed
      }

      previousQueryRef.current = { adaptedQuery, currentPage };

      if (hasQuery) {
        dispatch(
          getQueryStringResults(
            initialPath,
            { ...adaptedQuery, b_start: (currentPage - 1) * b_size },
            subrequestID,
            currentPage,
          ),
        );
      } else if (isImageGallery && !hasQuery) {
        dispatch(
          getQueryStringResults(
            initialPath,
            {
              ...adaptedQuery,
              b_size: 10000000000,
              query: [
                {
                  i: 'path',
                  o: 'plone.app.querystring.operation.string.relativePath',
                  v: '',
                },
              ],
            },
            subrequestID,
          ),
        );
      } else {
        dispatch(getContent(initialPath, null, null, currentPage));
      }
    }, [
      dispatch,
      hasQuery,
      adaptedQuery,
      initialPath,
      subrequestID,
      currentPage,
      isImageGallery,
      isSSR,
      b_size,
    ]);

    useEffect(() => {
      fetchResults();
    }, [fetchResults, currentPage]); // Ensure fetchResults is called whenever fetchResults or currentPage changes

    return (
      <div
        style={{
          minHeight: hasLoaded ? '0px' : '300px',
          transition: 'transform 1s ease', // Smooth transition for scaling
        }}
      >
        <MemoizedWrappedComponent
          {...props}
          onPaginationChange={(e, { activePage }) => setCurrentPage(activePage)}
          total={querystringResults?.[subrequestID]?.total}
          batch_size={b_size}
          currentPage={currentPage}
          totalPages={totalPages}
          prevBatch={prevBatch}
          nextBatch={nextBatch}
          listingItems={listingItems}
          hasLoaded={hasLoaded}
          isFolderContentsListing={showAsFolderListing}
        />
      </div>
    );
  }

  WithQuerystringResults.displayName = `WithQuerystringResults(${getDisplayName(
    WrappedComponent,
  )})`;

  return hoistNonReactStatics(WithQuerystringResults, MemoizedWrappedComponent);
}
