import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { flattenToAppURL, isInternalURL, withBlockExtensions } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

export const View = ({ className, data, detached, properties, style }) => {
  const href = data?.href?.[0]?.['@id'] || '';
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  // Load the Image component dynamically
  const Image = config.getComponent({ name: 'Image' }).component;

  const getSrcSet = (baseUrl) => {
    if (!baseUrl) return '';
    return `
      ${baseUrl}/@@images/image/mini 200w,
      ${baseUrl}/@@images/image/preview 400w,
      ${baseUrl}/@@images/image/teaser 600w,
      ${baseUrl}/@@images/image/large 800w,
      ${baseUrl}/@@images/image/larger 1000w,
      ${baseUrl}/@@images/image/great 1200w,
      ${baseUrl}/@@images/image/huge 1600w
    `;
  };

  const getSizes = (width) => {
    if (width <= 200) {
      return '200px';
    } else if (width <= 400) {
      return '400px';
    } else if (width <= 600) {
      return '600px';
    } else if (width <= 800) {
      return '800px';
    } else if (width <= 1000) {
      return '1000px';
    } else if (width <= 1200) {
      return '1200px';
    } else {
      return '1600px';
    }
  };

  // Use ResizeObserver to watch for changes in container size
  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(updateSize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Initial size setting
    updateSize();

    // Cleanup observer on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={cx(
        'block image align',
        {
          center: !Boolean(data.align),
          detached,
        },
        data.align,
        className,
      )}
      style={style}
    >
      {data.url && (
        <>
          {(() => {
            const imageUrl = flattenToAppURL(data.url);
            const srcSet = getSrcSet(imageUrl);
            const sizes = getSizes(containerWidth);

            const image = (
              <Image style={{ width: '100%', height: 'auto' }}
                className={cx({
                  'full-width': data.align === 'full',
                  large: data.size === 'l',
                  medium: data.size === 'm',
                  small: data.size === 's',
                })}
                item={
                  data.image_scales
                    ? {
                      '@id': data.url,
                      image_field: data.image_field,
                      image_scales: data.image_scales,
                    }
                    : undefined
                }
                src={
                  data.image_scales
                    ? undefined
                    : isInternalURL(data.url)
                      ? (() => {
                        if (data.size === 'l')
                          return `${imageUrl}/@@images/image`;
                        if (data.size === 'm')
                          return `${imageUrl}/@@images/image/preview`;
                        if (data.size === 's')
                          return `${imageUrl}/@@images/image/mini`;
                        return `${imageUrl}/@@images/image`;
                      })()
                      : data.url
                }
                srcSet={srcSet}   // Correct usage of srcSet
                     sizes={sizes}     // Adjust sizes dynamically based on container width
                alt={data.alt || ''}
                loading='lazy'
                responsive={true}
              />
            );

            if (href) {
              return (
                <UniversalLink
                  href={href}
                  openLinkInNewTab={data.openLinkInNewTab}
                >
                  {image}
                </UniversalLink>
              );
            } else {
              return image;
            }
          })()}
        </>
      )}
    </div>
  );
};

View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);
