export const links = [
  {
    name: 'About',
    link: '/about?utm_source=yc&utm_medium=about',
    styles: 'cd-nav-gallery',
    sublinks: [
      {
        name: 'Points of Pride',
        link: '/about/points-of-pride?utm_source=yc&utm_medium=about',
        image: '/image-repository/points-of-pride-rev1920.webp/@@images/image-2560-e38bd3732d39f75f38f77b9e67380172.webp',
      },
      {
        name: 'Locations and Directions',
        link: '/about/directions?utm_source=yc&utm_medium=about',
        image: '/image-repository/location-directions.svg/@@images/image',
      },
      {
        name: 'Buildings',
        link: '/about/buildings?utm_source=yc&utm_medium=about',
        image: '/institutional-effectiveness/accreditation/@@images/preview_image/preview',
      },
      {
        name: 'History',
        link: '/about/history?utm_source=yc&utm_medium=about',
        image: '/image-repository/history2.jpg/@@images/image/preview',
      },
      {
        name: 'Consumer Information for Students',
        link: '/about/consumer-information?utm_source=yc&utm_medium=about',
        image: '/about-york/points-of-pride/picture1.png/@@images/image/great',
      },
    ],
  },
  {
    name: 'Admissions',
    link: '/admissions?utm_source=yc&utm_medium=admissions',
    styles: 'cd-nav-icons',
    sublinks: [
      {
        name: 'Freshman Admissions',
        link: '/admissions/freshman?utm_source=yc&utm_medium=admissions',
        styles: 'item-1',
        description: 'I have never attended college',
      },
      {
        name: 'Transfer Admissions',
        link: '/admissions/transfer?utm_source=yc&utm_medium=admissions',
        styles: 'item-2',
        description: 'I studied at other post-secondary institutions',
      },
      {
        name: 'Graduate Admissions',
        link: '/admissions/graduate?utm_source=yc&utm_medium=admissions',
        styles: 'item-3',
        description: 'I graduated from a post-secondary institution',
      },
      {
        name: 'International Admissions',
        link: '/admissions/international?utm_source=yc&utm_medium=admissions',
        styles: 'item-4',
        description: 'I\'m not a US Resident / Citizen',
      },
      {
        name: 'Veterans Admission',
        link: '/veterans-affairs?utm_source=yc&utm_medium=admissions',
        styles: 'item-5',
        description: 'I served in the US military',
      },
      {
        name: 'Other Applicants',
        link: '/admissions/others?utm_source=yc&utm_medium=admissions',
        styles: 'item-6',
        description: 'Non-degree, Second Degree, Adult Learner, Senior Citizens',
      },
      {
        name: 'Admissions Team/Office Hours',
        link: '/admissions/team?utm_source=yc&utm_medium=admissions',
        styles: 'item-7',
        description: 'Meet the York College Admission Team, Virtual and In-person Walk-In Hours',
      },
      {
        name: 'Campus Tour',
        link: '/admissions/campus-tours?utm_source=yc&utm_medium=admissions',
        styles: 'item-8',
        description: 'Take a virtual Tour of our campus',
      },
      {
        name: 'Financial Aid',
        link: '/financial-aid?utm_source=yc&utm_medium=admissions',
        styles: 'item-9',
        description: 'I need help paying for college',
      },
    ],
  },
  {
    name: 'Academics',
    link: '/academics?utm_source=yc&utm_medium=academics',
    styles: 'cd-secondary-nav',
    sublinks: [
      {
        name: 'Academics Resources',
        link: '/academics?utm_source=yc&utm_medium=academics',
        styles: 'has-children',
        sublink: [
          { name: 'Academic Advisement Center', link: '/advisement?utm_source=yc&utm_medium=academics' },
          { name: 'Academic Affairs', link: '/academics/academic-affairs?utm_source=yc&utm_medium=academics' },
          { name: 'Academic Calendar', link: '/registrar/calendar?utm_source=yc&utm_medium=academics', styles: 'menustrong' },
          { name: 'Accelerate, Complete, Engage (ACE)', link: '/ace?utm_source=yc&utm_medium=academics' },
          { name: 'Bulletin - Graduate', link: 'https://york-graduate.catalog.cuny.edu/', styles: 'menustrong' },
          { name: 'Bulletin - Undergraduate', link: 'https://york-undergraduate.catalog.cuny.edu/', styles: 'menustrong' },
          { name: 'Collaborative Learning Center', link: '/collaborative-learning-center?utm_source=yc&utm_medium=academics' },
          { name: 'Course Schedule', link: '/registrar/course-schedule?utm_source=yc&utm_medium=academics' },
          { name: 'Honors Program', link: '/honors?utm_source=yc&utm_medium=academics' },
          { name: 'Office of Student Academic Services', link: '/osas?utm_source=yc&utm_medium=academics' },
          { name: 'Registrar', link: '/registrar?utm_source=yc&utm_medium=academics', styles: 'menustrong' },
          { name: 'Undergraduate Research', link: '/undergraduate-research?utm_source=yc&utm_medium=academics' },
          { name: 'Writing Across the Curriculum', link: '/wac?utm_source=yc&utm_medium=academics' },
        ],
      },
      {
        name: 'School of Arts & Sciences',
        link: '/arts-and-sciences?utm_source=yc&utm_medium=academics',
        styles: 'has-children',
        sublink: [
          { name: 'Behavioral Sciences', link: '/behavioral-sciences?utm_source=yc&utm_medium=academics' },
          { name: 'Biology', link: '/biology?utm_source=yc&utm_medium=academics' },
          { name: 'Chemistry', link: '/chemistry?utm_source=yc&utm_medium=academics' },
          { name: 'Earth and Physical Sciences', link: '/earth-and-physical-sciences?utm_source=yc&utm_medium=academics' },
          { name: 'English', link: '/english?utm_source=yc&utm_medium=academics' },
          { name: 'History, Philosophy, and Anthropology', link: '/history-and-philosophy?utm_source=yc&utm_medium=academics' },
          { name: 'Mathematics and Computer Science', link: '/mathematics-and-computer-science?utm_source=yc&utm_medium=academics' },
          { name: 'Performing and Fine Arts', link: '/performing-and-fine-arts?utm_source=yc&utm_medium=academics' },
          { name: 'World Languages, Literatures, and Humanities', link: '/foreign-languages-esl-humanities?utm_source=yc&utm_medium=academics' },
        ],
      },
      {
        name: 'School of Business & Information Systems',
        link: '/business-and-information-systems?utm_source=yc&utm_medium=academics',
        styles: 'has-children',
        sublink: [
          { name: 'Accounting and Finance', link: '/accounting-and-finance?utm_source=yc&utm_medium=academics' },
          { name: 'Business and Economics', link: '/business-economics?utm_source=yc&utm_medium=academics' },
          { name: 'CUNY Aviation Institute', link: '/aviation-institute?utm_source=yc&utm_medium=academics' },
        ],
      },
      {
        name: 'School of Health Sciences & Professional Programs',
        link: '/health-sciences-and-professional-programs?utm_source=yc&utm_medium=academics',
        styles: 'has-children',
        sublink: [
          { name: 'Health & Human Performance', link: '/health-human-performance?utm_source=yc&utm_medium=academics' },
          { name: 'Health Professions', link: '/health-professions?utm_source=yc&utm_medium=academics' },
          { name: 'Nursing', link: '/nursing?utm_source=yc&utm_medium=academics' },
          { name: 'Occupational Therapy', link: '/occupational-therapy?utm_source=yc&utm_medium=academics' },
          { name: 'Social Work', link: '/social-work?utm_source=yc&utm_medium=academics' },
          { name: 'Teacher Education', link: '/teacher-education?utm_source=yc&utm_medium=academics' },
        ],
      },
    ],
  },
  {
    name: 'Student Life',
    link: '/current-student?utm_source=yc&utm_medium=studentlife',
    styles: 'cd-secondary-nav',
    sublinks: [
      {
        name: 'Online Services',
        styles: 'has-children',
        sublink: [
          { name: 'Bookstore', link: '/news/2018/the-york-college-online-bookstore?utm_source=yc&utm_medium=studentlife' },
          { name: 'CUNY ePermit', link: 'https://www2.cuny.edu/about/administration/offices/registrar/resources/ePermit/' },
          { name: 'DegreeWorks', link: 'https://degreeworks.cuny.edu/Dashboard_yk' },
          { name: 'Dropbox', link: 'https://dropbox.cuny.edu/' },
          { name: 'IT Self Service', link: '/it/service-delivery-unit/y-connect?utm_source=yc&utm_medium=studentlife' },
          { name: 'LinkedIn Learning', link: '/ctlet/lynda?utm_source=yc&utm_medium=studentlife', styles: 'menustrong' },
          { name: 'MyID / Password Management', link: '/myid?utm_source=yc&utm_medium=studentlife' },
          { name: 'Navigate', link: '/academics/navigate?utm_source=yc&utm_medium=studentlife' },
          { name: 'Parking Permits and Violations', link: 'https://parking.york.cuny.edu/' },
          { name: 'Secure Documents Portal', link: 'https://documents.york.cuny.edu/' },
          { name: 'Tutor.com', link: '/tutor?utm_source=yc&utm_medium=studentlife', styles: 'menustrong' },
          { name: 'VPN Access', link: '/it/network-access/vpn?utm_source=yc&utm_medium=studentlife', styles: 'menustrong' },
          { name: 'Zoom', link: 'https://cuny.zoom.us' },
        ],
      },
      {
        name: 'Student Affairs and Enrollment Management',
        link: '/student-development?utm_source=yc&utm_medium=studentlife',
        styles: 'has-children',
        sublink: [
          { name: 'Athletics', link: 'http://www.yorkathletics.com/?utm_source=yc&utm_medium=studentlife' },
          { name: 'Career Services', link: '/career-services?utm_source=yc&utm_medium=studentlife' },
          { name: 'Child and Family Center', link: '/child-and-family-center?utm_source=yc&utm_medium=studentlife' },
          { name: 'First-Year Experience (FYE)', link: '/student-development/fye?utm_source=yc&utm_medium=studentlife' },
          { name: 'Male Initiative Program', link: '/mens-center?utm_source=yc&utm_medium=studentlife' },
          { name: 'Mentoring', link: '/student-development/mentoring?utm_source=yc&utm_medium=studentlife' },
          { name: 'Office of Enrollment Management', link: '/enrollment-mngmt-office?utm_source=yc&utm_medium=studentlife', styles: 'has-children' },
          { name: 'Office of Ombudsperson', link: '/ombudsperson?utm_source=yc&utm_medium=studentlife' },
          { name: 'Percy E. Sutton SEEK program', link: '/seek?utm_source=yc&utm_medium=studentlife' },
          { name: 'Office of Student Activities', link: '/student-development/student-activities?utm_source=yc&utm_medium=studentlife', styles: 'has-children' },
          { name: 'Veteran\'s Affairs', link: '/veterans-affairs?utm_source=yc&utm_medium=studentlife' },
          { name: 'Wellness and Resources', link: '/cares?utm_source=yc&utm_medium=studentlife', styles: 'has-children' },
        ],
      },
      {
        name: 'Student Resources',
        link: '/current-student?utm_source=yc&utm_medium=studentlife',
        styles: 'has-children',
        sublink: [
          { name: 'Behavioral Intervention Team (BIT)', link: '/student-development/bit?utm_source=yc&utm_medium=studentlife' },
          { name: 'Calendar', link: '/events?utm_source=yc&utm_medium=studentlife' },
          { name: 'Center for Teaching, Learning and Educational Technologies (CTLET)', link: '/ctlet?utm_source=yc&utm_medium=studentlife' },
          { name: 'Consumer Information', link: '/about/consumer-information?utm_source=yc&utm_medium=studentlife' },
          { name: 'Public Safety', link: '/public-safety?utm_source=yc&utm_medium=studentlife' },
          { name: 'Scholarships Center', link: '/scholarship?utm_source=yc&utm_medium=studentlife', styles: 'menustrong' },
          { name: 'Senate', link: '/senate?utm_source=yc&utm_medium=studentlife', styles: 'menustrong' },
          { name: 'Study Abroad', link: '/study-abroad?utm_source=yc&utm_medium=studentlife' },
          { name: 'The Milton G. Bassin Performing Arts Center', link: '/pac?utm_source=yc&utm_medium=studentlife' },
          { name: 'Welcome Center', link: '/welcome-center?utm_source=yc&utm_medium=studentlife', styles: 'menustrong' },
        ],
      },
      { name: 'Brightspace', link: 'https://www.cuny.edu/about/administration/offices/cis/technology-services/brightspace/', styles: 'boxhalf' },
      { name: 'CUNYfirst', link: 'https://home.cunyfirst.cuny.edu/', styles: 'boxhalf' },
      { name: 'EMAIL / Office 365', link: 'https://www.outlook.com/owa/yorkmail.cuny.edu', styles: 'boxhalf' },
      { name: 'Schedule Builder', link: '/registrar/schedule-builder', styles: 'boxhalf' },
      { name: 'Academic Planning', link: '/advisement?utm_source=yc&utm_medium=studentlife' },
      { name: 'Financial Aid', link: '/financial-aid?utm_source=yc&utm_medium=studentlife', styles: 'menustrong' },
      { name: 'Registration', link: '/registrar?utm_source=yc&utm_medium=studentlife', styles: 'menustrong' },
      { name: 'Tuition and Payment', link: '/bursar?utm_source=yc&utm_medium=studentlife', styles: 'menustrong' },
      { name: 'Library', link: '/library?utm_source=yc&utm_medium=studentlife' },
    ],
  },
  {
    name: 'Faculty / Staff',
    link: '/faculty-staff?utm_source=yc&utm_medium=faculty',
    styles: 'cd-secondary-nav',
    sublinks: [
      {
        name: 'Online Services',
        link: '/faculty-staff?utm_source=yc&utm_medium=faculty',
        styles: 'has-children',
        sublink: [
          { name: 'B&G Work Order', link: '/administrative/office-of-facilities-and-planning/bg/archibus?utm_source=yc&utm_medium=faculty' },
          { name: 'Bookstore', link: '/news/2018/the-york-college-online-bookstore?utm_source=yc&utm_medium=faculty' },
          { name: 'Cybersecurity Awareness Training', link: 'https://bbhosted.cuny.edu/webapps/blackboard/execute/announcement?method=search&context=course_entry&course_id=_2323641_1&handle=announcements_entry&mode=view', styles: 'menustrong' },
          { name: 'DegreeWorks', link: 'https://degreeworks.cuny.edu/' },
          { name: 'Dropbox', link: 'https://dropbox.cuny.edu/' },
          { name: 'LinkedIn Learning', link: '/ctlet/lynda?utm_source=yc&utm_medium=faculty' },
          { name: 'Parking Permits and Violations', link: 'https://parking.york.cuny.edu' },
          { name: 'Qualtrics', link: '/it/service-delivery-unit/qualtrics?utm_source=yc&utm_medium=faculty' },
          { name: 'VPN Access', link: '/it/network-access/vpn?utm_source=yc&utm_medium=faculty', styles: 'menustrong' },
          { name: 'York Assessment Management System (YAMS)', link: '/yams?utm_source=yc&utm_medium=faculty' },
          { name: 'York Website/CMS', link: '/login?return_url=/dashboard&utm_source=yc&utm_medium=faculty' },
          { name: 'Zoom', link: 'https://cuny.zoom.us' },
        ],
      },
      {
        name: 'Administration',
        link: '/administrative?utm_source=yc&utm_medium=faculty',
        styles: 'has-children',
        sublink: [
          { name: 'Environment Health and Safety', link: '/administrative/office-of-facilities-and-planning/environmental?utm_source=yc&utm_medium=faculty' },
          { name: 'Facilities and Planning', link: '/administrative/office-of-facilities-and-planning?utm_source=yc&utm_medium=faculty' },
          { name: 'Financial Planning and Budget', link: '/administrative/business-office?utm_source=yc&utm_medium=faculty' },
          { name: 'Government Relations and Strategic Initiatives', link: '/president/government-and-community-relations?utm_source=yc&utm_medium=faculty' },
          { name: 'Human Resources', link: '/human-resources?utm_source=yc&utm_medium=faculty', styles: 'menustrong' },
          { name: 'Information Technology', link: '/it?utm_source=yc&utm_medium=faculty' },
          { name: 'Office of Institutional Advancement', link: '/ia?utm_source=yc&utm_medium=faculty' },
          { name: 'Office of Institutional Effectiveness', link: '/institutional-effectiveness?utm_source=yc&utm_medium=faculty' },
          { name: 'Office of the President', link: '/president?utm_source=yc&utm_medium=faculty', styles: 'menustrong' },
          { name: 'Public Safety', link: '/public-safety?utm_source=yc&utm_medium=faculty', styles: 'menustrong' },
        ],
      },
      {
        name: 'Resources',
        styles: 'has-children',
        sublink: [
          { name: 'Behavioral Intervention Team (BIT)', link: '/student-development/bit?utm_source=yc&utm_medium=faculty' },
          { name: 'Calendar', link: '/events?utm_source=yc&utm_medium=faculty' },
          { name: 'Center for Teaching, Learning and Educational Technologies (CTLET)', link: '/ctlet?utm_source=yc&utm_medium=faculty' },
          { name: 'Library', link: '/library?utm_source=yc&utm_medium=faculty', styles: 'menustrong' },
          { name: 'Marketing and Communications', link: '/marketing?utm_source=yc&utm_medium=faculty' },
          { name: 'Office of the Bursar', link: '/bursar?utm_source=yc&utm_medium=faculty' },
          { name: 'Printing Services', link: '/administrative/printing-services?utm_source=yc&utm_medium=faculty' },
          { name: 'Senate', link: '/senate?utm_source=yc&utm_medium=faculty', styles: 'menustrong' },
          { name: 'Wellness and Resources', link: '/cares?utm_source=yc&utm_medium=faculty' },
        ],
      },
      { name: 'AEMS | HR/PR Assist', link: '/human-resources/current-employee?utm_source=yc&utm_medium=faculty', styles: 'boxhalf' },
      { name: 'Brightspace', link: 'https://www.cuny.edu/about/administration/offices/cis/technology-services/brightspace/', styles: 'boxhalf' },
      { name: 'CUNYfirst', link: 'https://home.cunyfirst.cuny.edu/', styles: 'boxhalf' },
      { name: 'EMAIL / Office 365', link: 'https://login.microsoftonline.com/?whr=login.cuny.edu', styles: 'boxhalf' },
      { name: 'CUNYBuy', link: 'https://solutions.sciquest.com/apps/Router/SAMLAuth/CUNY', styles: 'menustrong' },
      { name: 'IT Self Service', link: '/it/service-delivery-unit/y-connect?utm_source=yc&utm_medium=faculty' },
      { name: 'Navigate', link: '/academics/navigate?utm_source=yc&utm_medium=faculty' },
    ],
  },
  {
    name: 'Alumni / Friends',
    link: '/alumni?utm_source=yc&utm_medium=alumni',
    styles: 'cd-nav-gallery',
    sublinks: [
      {
        name: 'Institutional Advancement',
        link: '/ia?utm_source=yc&utm_medium=alumni',
        image: '/image-repository/history.jpg/@@images/image/preview',
      },
      {
        name: 'YC Foundation, Inc.',
        link: '/ia/foundation-board?utm_source=yc&utm_medium=alumni',
        image: '/image-repository/friends-ycf.svg/@@images/image/preview',
      },
      {
        name: 'Alumni Association, Inc.',
        link: '/alumni/association?utm_source=yc&utm_medium=alumni',
        image: '/image-repository/friends-alumni-1.jpg/@@images/image/preview',
      },
      {
        name: 'Alumni Relations',
        link: '/ia/devalum?utm_source=yc&utm_medium=alumni',
        image: '/image-repository/alumni-resources.jpg/@@images/image/preview',
      },
      {
        name: 'Rent a Space',
        link: '/rent-a-space?utm_source=yc&utm_medium=alumni',
        image: '/image-repository/rental.webp/@@images/image/preview',
      },
    ],
  },
];
