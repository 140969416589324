import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import cx from 'classnames';

const ContentsBreadcrumbs = ({ rootTitle }) => {
  const breadcrumbs = useSelector((state) => state.breadcrumbs.items);
  // const breadcrumbsHome = useSelector((state) => state.breadcrumbsHome);
  const breadcrumbsHome = useSelector((store) => store.breadcrumbs.root);
  const navroot = useSelector((state) => state.navroot?.data?.navroot);

  return (
    <div className='ui breadcrumb'>
      <Link
        to='/contents'
        className='section'
      >York College</Link>
      <div className='divider'> /</div>
      <Link
        to={`${breadcrumbsHome}/contents`}
        className='section'
        title={rootTitle || navroot?.title || 'York College'}
      >
        {rootTitle || navroot?.title || 'York College'}
      </Link>
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb.url}>
          <div className='divider'> /</div>
          <Link
            to={`${flattenToAppURL(breadcrumb.url)}/contents`}
            className={cx('section', {
              disabled: index === breadcrumbs.length - 1,
            })}
          >
            {breadcrumb.title}
          </Link>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ContentsBreadcrumbs;
